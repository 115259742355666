import { Component, Inject, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { SessionService } from "src/app/_services/session.service";
import { BaseComponent } from "../base.component";

@Component({
    selector: 'app-errorbox',
    templateUrl: './errorbox.component.html'
})

export class ErrorBoxComponent extends BaseComponent {

    @Input()
    mode: string = '';

    constructor(public sessionService: SessionService,
        public router: Router,
        public route: ActivatedRoute,
        @Inject(MAT_SNACK_BAR_DATA) private data: string) {
        super(sessionService, router, route);

        this.mode = data;

    }

}