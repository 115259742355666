<mat-chip-list>
    <mat-chip color="secondary">
        <a mat-button href="contact">
            {{ '__de__=Aktuelles Projekt endet bald__en__=Current project ends soon' |
            translate}}
        </a>
        <mat-icon class="chip-icon" color="accent">alarm</mat-icon>
    </mat-chip>
    <mat-chip color="accent" selected>
        <a mat-button href="contact">
            {{ '__de__=Offen für Anfragen__en__=Open to requests' |
            translate}}
        </a>
        <mat-icon class="chip-icon">check_small</mat-icon>
    </mat-chip>
</mat-chip-list>