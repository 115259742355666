import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: 'app-stars',
    templateUrl: './stars.component.html',
    styleUrls: ['./stars.component.scss']
})

export class StarsComponent {

    @Input()
    stars: number = 0;


    @Input()
    count: number = 0;


    fill() {
        let size = this.count - this.stars;
        if (size < 0) size = 0;
        return new Array(size);
    }
    print() {
        let size = this.stars;
        if (size < 0) size = 0;
        return new Array(size);
    }

}