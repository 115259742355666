import { Component, OnInit, ViewChild, AfterViewInit, ViewEncapsulation, Inject, OnDestroy } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { MatButton } from '@angular/material/button';
import { Meta } from '@angular/platform-browser';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { SessionService } from './_services/session.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('avatarClick', [
      state('start', style({
        transform: 'rotate(0deg)'
      })),
      state('end', style({
        transform: 'rotate(360deg)'
      })),
      transition('start => end', animate('750ms linear')),
      transition('end => start', animate('500ms linear')),
    ]),
    trigger('menuClick', [
      state('start', style({
        transform: 'rotate(180deg) scale(0,0)'
      })),
      state('end', style({
        transform: 'rotate(0deg) scale(1,1)'
      })),
      transition('start => end', animate('250ms linear')),
      transition('end => start', animate('250ms linear')),
    ])
  ]
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('sidenav')
  sideNav: MatSidenav;

  @ViewChild('menuButton')
  menuButton: MatButton;

  subs: Subscription[] = [];

  avatarState = 'start';
  menuState = 'end';
  avatarIsClickable = false;
  menuOpen = false;

  currentTheme: string;

  animations: string[] = [];


  title = 'Lead Software Architect';
  isHandset = false;
  icon = 'menu';

  constructor(
    private breakingPointObserver: BreakpointObserver,
    private sessionService: SessionService,
    private meta: Meta,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.meta.addTag({ name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, shrink-to-fit=no' })

    this.currentTheme = this.sessionService.getSession().theme;
    this.setTheme();

    this.subs.push(this.sessionService.currentThemeChange.subscribe(value => {
      this.currentTheme = value;
      this.setTheme();
    }));
  }

  ngOnInit(): void {
    this.subs.push(this.breakingPointObserver.observe(
      [
        '(min-width: 700px)'
      ]
    )
      .subscribe((state: BreakpointState) => {
        this.isHandset = !state.matches;
        if (this.menuOpen) {
          this.sideNav.toggle();
        }
      }));
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  ngAfterViewInit() {
    this.subs.push(this.sideNav.openedChange.subscribe((isOpen) => {
      this.menuOpen = isOpen;
      if (isOpen) {
        this.icon = 'clear';
      } else {
        this.icon = 'menu';
        this.menuState = 'end';
      }
      setTimeout(function () {
        window.scrollTo(0, 0);
      }, 5);
    }));
  }

  onMenuClick(event: Event) {
    const index = this.animations.indexOf('menuClick');
    if (index == -1 && this.isHandset) {
      this.animations.push('menuClick');
      this.menuState = (this.menuState == 'start' ? 'end' : 'start');
    }
  }

  onAvatarClick() {
    const index = this.animations.indexOf('avatarClick');
    if (this.avatarIsClickable && index == -1) {
      this.animations.push('avatarClick');
      this.avatarState = (this.avatarState == 'start' ? 'end' : 'start');
    }
  }

  setTheme() {
    if (this.currentTheme == 'light') {
      this.document.body.classList.remove('dark-theme');
    } else {
      this.document.body.classList.add('dark-theme');
    }
  }


  onAnimiationStarted($event) {
    this.avatarIsClickable = false;
  }

  onAnimiationEnded($event) {
    this.avatarIsClickable = true;
    const index = this.animations.indexOf($event.triggerName);
    if (index > -1) {
      this.animations.splice(index, 1);

      if ($event.triggerName == 'avatarClick') {
        if (this.currentTheme == 'light') {
          this.sessionService.setTheme('dark');
        } else {
          this.sessionService.setTheme('light');
        }
      } else if ($event.triggerName == 'menuClick') {
        if (this.isHandset) {
          this.sideNav.toggle();
        }
      }

    }
  }


}
