<mat-card>
    <mat-card-title>
        {{ '__de__=Mein Enwicklungsansatz__en__=My development approach' | translate }}
    </mat-card-title>
    <mat-card-content>
        <div>
            Es gibt eine millionen Wege Software zu entwickeln und jeder Ansatz hat seine Existenzberechtigung.
            <br><br>
            Software ist zudem einem ständigen Wandel unterworfen. Was heute als "up to date" gilt, wird bald
            schon als "überholt" bezeichnet werden.
            <br><br>
            Es gibt jedoch Prinzipien, dieser notwendigen Veränderung mit
            einer robusten Architektur zu begegnen, um beispielsweise zu verhindern, dass ein Produkt mit der Zeit zum
            starren Monolithen wird.
        </div>
    </mat-card-content>
</mat-card>
<br>
<mat-card>
    <mat-card-title class="smallerTitle">
        {{ '__de__=Nichts ist perfekt__en__=Nothing is perfect' | translate }}
    </mat-card-title>

    <mat-card-content>
        <div>
            Mir ist es sehr wichtig zu betonen, dass ich <u>nicht</u> behaupten möchte, dass der hier beschriebene
            Ansatz
            "perfekt" sei oder besser, als der eines anderen Entwicklers.
            <br><br>
            <strong>Im
                Gegenteil:</strong> Nur mit offener Kommunikation, verschiedenen Sichtweisen und permanenter Fortbildung
            erreicht man eine nachhaltige Architektur.
        </div>
    </mat-card-content>
</mat-card>