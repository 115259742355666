import { Component, Injectable, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SessionService } from '../_services/session.service';

@Component({
    selector: 'app-base',
    template: ''
})

@Injectable()
export abstract class BaseComponent implements OnInit, OnDestroy {

    public currentLanguage: string = 'de';
    subs: Subscription[] = [];

    constructor(
        public sessionService: SessionService,
        public router: Router,
        public route: ActivatedRoute
    ) {
        this.currentLanguage = this.sessionService.getSession().language;
        this.subs.push(this.sessionService.currentLanguageChange.subscribe(value => {
            this.currentLanguage = value;
        }));
    }

    ngOnInit(): void {
        this.subs.push(this.route.queryParamMap.subscribe((paramsMap: any) => {
            var langFilter = String(paramsMap.params.lang);
            if (langFilter) {
                if (this.currentLanguage != langFilter) {
                    this.sessionService.setLanguage(langFilter);
                }
            }
        }));
    }

    ngOnDestroy(): void {
        this.subs.forEach(sub => sub.unsubscribe());
    }

    navigateToError(mode: string) {
        this.router.navigate(['error'], {
            state: {
                mode: mode
            }
        })
    }




}