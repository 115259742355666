<mat-toolbar>
  <mat-toolbar-row>
    <div class="flexContainer">
      <div class="flexRow">
        <div *ngIf="isHandset" class="flexCol fitContentSize">
          <button mat-icon-button (click)="onMenuClick($event)">
            <mat-icon class="menu-color">{{ icon }}</mat-icon>
          </button>
        </div>
        <div [class]="isHandset ? 'flexCol centerItems' : 'flexCol fitContentSize'">
          <div mat-card-avatar (click)=" onAvatarClick()" [@avatarClick]="avatarState"
            (@avatarClick.start)="onAnimiationStarted($event)" (@avatarClick.done)="onAnimiationEnded($event)"
            [@menuClick]="menuState" (@menuClick.start)="onAnimiationStarted($event)"
            (@menuClick.done)="onAnimiationEnded($event)" class="header-avatar">
          </div>
        </div>
        <div *ngIf="!isHandset" class="flexCol">
          <app-mainmenu></app-mainmenu>
        </div>
        <div class="flexCol fitContentSize">
          <app-settings></app-settings>
        </div>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
<mat-sidenav-container>
  <mat-sidenav #sidenav="matSidenav" (click)="onMenuClick($event)" mode="over">
    <app-mainmenu mode="vertical"></app-mainmenu>
  </mat-sidenav>
  <mat-sidenav-content>
    <main>
      <router-outlet></router-outlet>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>