import { Component } from '@angular/core';
import { BaseComponent } from '../../base.component'

@Component({
    selector: 'app-privacy-content',
    templateUrl: './privacycontent.component.html'
})

export class PrivacyContentComponent extends BaseComponent {
}

