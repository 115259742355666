<div *ngIf="mode == 'horizontal'" class="horizontal-menu">
    <div class="flexRow fitContentSize">
        <div class="flexCol" *ngFor="let menuPoint of menuPoints">
            <a mat-button *ngIf="menuPoint.route != null" routerLink="{{ menuPoint.route }}">
                <span>{{ menuPoint.text }}</span>
            </a>
            <a mat-button *ngIf="menuPoint.action != null" (click)="onClick(menuPoint)">
                <span>{{ menuPoint.text }}</span>
            </a>
            <a mat-button *ngIf="menuPoint.link != null" href="{{ menuPoint.link }}" target="_blank">
                <span>{{ menuPoint.text }}</span>
            </a>
        </div>

    </div>
</div>
<div *ngIf="mode != 'horizontal'">
    <mat-nav-list *ngFor="let menuPoint of menuPoints">
        <a mat-list-item *ngIf="menuPoint.route != null" routerLink="{{ menuPoint.route }}">
            <mat-icon class="material-icons-outlined">{{ menuPoint.icon }}</mat-icon>
            <span>{{ menuPoint.text }}</span>
        </a>
        <a mat-list-item *ngIf="menuPoint.action != null" (click)="onClick(menuPoint)">
            <mat-icon class="material-icons-outlined">{{ menuPoint.icon }}</mat-icon>
            <span>{{ menuPoint.text }}</span>
        </a>
        <a mat-list-item *ngIf="menuPoint.link != null" href="{{ menuPoint.link }}" target="_blank">
            <mat-icon class="material-icons-outlined">{{ menuPoint.icon }}</mat-icon>
            <span>{{ menuPoint.text }}</span>
        </a>
        <mat-divider></mat-divider>
    </mat-nav-list>
</div>