import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatChipsModule } from '@angular/material/chips'


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';

import { MainmenuComponent } from './_components/mainmenu/mainmenu.component';
import { PrivacyContentComponent } from './_components/content/privacycontent/privacycontent.component';
import { ContactInfoComponent } from './_components/contactinfo/contactinfo.component';
import { PrivacyDialogComponent } from './_components/content/privacycontent/privacydialog.component';
import { MainSkillsContentComponent } from './_components/content/mainskillscontent/mainskillscontent.component';
import { AvailabilityComponent } from './_components/availability/availability.component';

import { TranslatePipe } from './_pipes/translate.pipe';
import { ProfileComponent } from './profile/profile.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { StarsComponent } from './_components/stars/stars.component';
import { ShrinkPipe } from './_pipes/shrink.pipe';
import { ErrorBoxComponent } from './_components/errorbox/errorbox.component';
import { ErrorComponent } from './error/error.component';
import { LeaveSiteDialogComponent } from './_components/content/leavesitecontent/leavesitedialog.component';
import { SettingsComponent } from './_components/settings/settings.component';
import { StackComponent } from './stack/stack.component';

@NgModule({
  declarations: [
    AppComponent,

    MainmenuComponent,
    SettingsComponent,
    PrivacyContentComponent,
    PrivacyDialogComponent,
    ContactInfoComponent,
    StarsComponent,
    AvailabilityComponent,

    HomeComponent,
    ContactComponent,
    DisclaimerComponent,

    ShrinkPipe,
    TranslatePipe,
    ProfileComponent,
    ContactFormComponent,
    PrivacyComponent,
    ErrorBoxComponent,
    ErrorComponent,
    LeaveSiteDialogComponent,
    StackComponent,
    MainSkillsContentComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatToolbarModule, MatMenuModule, MatButtonModule, MatIconModule, MatSlideToggleModule, MatRadioModule,
    MatFormFieldModule, MatSelectModule, MatInputModule, MatCheckboxModule, MatCardModule, MatDialogModule,
    MatSidenavModule, MatListModule, MatSnackBarModule, MatTooltipModule, MatChipsModule
  ],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'standard' } }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
