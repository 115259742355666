<div *ngIf="mode == ''">
    <mat-card>
        <mat-card-title>
            {{ '__de__=Fehler !__en__=Error !' | translate }}
        </mat-card-title>
        <mat-card-content>
            <div *ngIf="currentLanguage == 'de'">
                Es ist ein unerwarteter Fehler aufgetreten ! Bitte entschuldigen Sie die Un­an­nehm­lich­keiten.
            </div>
            <div *ngIf="currentLanguage == 'en'">
                There has been an unexpected error ! Please accept my apologies for the inconveniences.
            </div>
        </mat-card-content>
    </mat-card>
</div>

<div *ngIf="mode == 'noServiceContact'">
    <mat-card>
        <mat-card-title>
            {{ '__de__=Fehler !__en__=Error !' | translate }}
        </mat-card-title>
        <mat-card-content>
            <div *ngIf="currentLanguage == 'de'">
                Aufgrund eines technischen Problems, steht das Kontaktformular aktuell leider nicht zur Verfügung. Bitte
                kontaktieren Sie mich persönlich und entschuldigen Sie die Un­an­nehm­lich­keiten.
            </div>
            <div *ngIf="currentLanguage == 'en'">
                Due to a technical problem the contact form is not available at the moment. Please contact me
                in person and accept my apologies for the inconveniences.
            </div>
        </mat-card-content>
    </mat-card>
    <br>
    <mat-card>
        <mat-card-content>
            <app-contactinfo [hideAddress]=" true" [hideUrl]="true"></app-contactinfo>
        </mat-card-content>
    </mat-card>
</div>