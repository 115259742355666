import { BreakpointObserver, Breakpoints, BreakpointState } from "@angular/cdk/layout";
import { OnDestroy, Pipe, PipeTransform } from "@angular/core";
import { Subscription } from "rxjs";

@Pipe({
    name: 'shrink',
    pure: false
})

export class ShrinkPipe implements PipeTransform, OnDestroy {

    breakpoint: string;
    subs: Subscription[] = [];

    constructor(private breakingPointObserver: BreakpointObserver) {
        this.subs.push(breakingPointObserver.observe([
            '(max-width: 360px)',
            '(min-width: 361px) and (max-width: 599.98px)',
            Breakpoints.Small,
            Breakpoints.Medium,
            Breakpoints.Large,
            Breakpoints.XLarge
        ]).subscribe((state: BreakpointState) => {
            if (state.breakpoints['(max-width: 360px)']) {
                this.breakpoint = 'XXSmall';
            }
            else if (state.breakpoints['(min-width: 361px) and (max-width: 599.98px)']) {
                this.breakpoint = 'XSmall';
            }
            else if (state.breakpoints[Breakpoints.Small]) {
                this.breakpoint = 'Small';
            }
            else if (state.breakpoints[Breakpoints.Medium]) {
                this.breakpoint = 'Medium';
            }
            else if (state.breakpoints[Breakpoints.Large]) {
                this.breakpoint = 'Large';
            }
            else if (state.breakpoints[Breakpoints.XLarge]) {
                this.breakpoint = 'XLarge';
            } else {
                this.breakpoint = 'default';
            }
            console.log(this.breakpoint);
        }));
    }
    ngOnDestroy(): void {
        this.subs.forEach(sub => sub.unsubscribe());
    }

    transform(multiScreenSizeContent: Array<string>) {
        let result: string;
        if (multiScreenSizeContent[this.breakpoint]) {
            result = multiScreenSizeContent[this.breakpoint];
        }
        if (result == null && multiScreenSizeContent['default']) {
            result = multiScreenSizeContent['default'];
        }
        return result;
    }

}