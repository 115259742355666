<mat-chip-list>
    <mat-chip>
        {{ '__de__=Derzeit in einem Projekt__en__=Currently in a project' |
        translate}}</mat-chip>
    <mat-chip color="accent" selected>
        <a mat-button href=" contact">
            {{ '__de__=Aber offen für Anfragen__en__=But open to requests' |
            translate}}
        </a>
        <mat-icon class="chip-icon">check_small</mat-icon>
    </mat-chip>
</mat-chip-list>