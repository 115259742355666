<div class="flexContainer">
    <div class="flexRow">
        <div class="flexCol">

            <div *ngIf="step == 0">
                <mat-card>
                    <mat-card-content>
                        <div class="flexContainer">
                            <div class="flexRow">
                                <div class="flexCol">
                                    <div *ngIf="currentLanguage == 'de'">
                                    </div>
                                    <div *ngIf="currentLanguage == 'en'">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</div>