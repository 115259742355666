<div>
    <span *ngIf="!hideAddress">
        Sascha Paulus<br>
        Flurstr. 10<br>
        51645 Gummersbach<br>
        {{ '__de__=Deutschland__en__=Germany' | translate }}<br><br>
    </span>
    {{ '__de__=Telefon__en__=Phone' | translate }}: +49 (0)2261 - 9153337<br>

    <div class="flexContainer" (mouseover)="show()">
        <div class="flexRow">
            <div class="flexCol fitContentSize">
                <mat-icon (click)="onClick()">local_post_office</mat-icon>
            </div>
            <div class="flexCol fitContentSize">
                <a #email (click)="onClick()">
                    {{ '__de__=per E-Mail__en__=by e-mail' | translate }}
                </a>
            </div>
        </div>
    </div>
    <span *ngIf="!hideUrl">www.saschapaulus.de</span>
    <div *ngIf="showTaxId">
        <br>
        <span *ngIf="currentLanguage == 'de'">
            Umsatzsteuer-Identifikationsnummer gemäß Umsatzsteuergesetz: DEXXXXXXXXXX
        </span>
        <span *ngIf="currentLanguage == 'en'">
            German VAT Tax Number: DEXXXXXXXXXX
        </span>
    </div>
</div>