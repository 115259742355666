import { Component } from '@angular/core';
import { BaseComponent } from '../_components/base.component'

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent extends BaseComponent {
}
