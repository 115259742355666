<app-availability></app-availability>
<br>
<mat-card>
    <mat-card-content>
        <a *ngIf="currentLanguage == 'de'" mat-stroked-button color="primary"
            href="/downloads/Sascha-Paulus-Profil-2024-Public.pdf"
            download="Sascha-Paulus-Profil-Public.pdf">Entwicklerprofil herunterladen (PDF)</a>
        <a *ngIf="currentLanguage == 'en'" mat-stroked-button color="primary"
            href="/downloads/Sascha-Paulus-Profile-2024-Public.pdf" download="Sascha-Paulus-Profil-Public.pdf">Download
            Developer Profile (PDF)</a>
    </mat-card-content>
</mat-card>
<br>
<mat-card>
    <mat-card-title class="smallerTitle">
        {{ '__de__=Ausführliches Profil__en__=Complete profile' | translate }}
    </mat-card-title>
    <mat-card-content>
        <div *ngIf="currentLanguage == 'de'">
            Wenn Sie mehr Informationen wie Vita, Projektdetails, Referenzen, Zeugnisse, etc. benötigen,
            können Sie gerne die ausführliche Version meines Profils ({{numberOfPage}}+ Seiten) anfordern.
        </div>
        <div *ngIf="currentLanguage == 'en'">
            If you need more information like my vita, details of projects, references, letters of recommendation,
            please feel free to request my complete profile ({{numberOfPage}}+ pages).
        </div>
        <br>
        <button mat-stroked-button color="primary" [routerLink]="['/contact']" [queryParams]="{filter: 'profile'}">{{
            '__de__=Ausführliches Profil anfordern__en__=Request Complete
            Profile' | translate
            }}</button>
    </mat-card-content>
</mat-card>