<mat-card>
    <mat-card-title>
        {{ '__de__=Kontakt__en__=Contact' | translate }}
    </mat-card-title>
    <mat-card-content>
        <div *ngIf="currentLanguage == 'de'">
            Ich freue mich sehr über Ihr Interesse.
        </div>
        <div *ngIf="currentLanguage == 'en'">
            I appreciate your interest in my person.
        </div>
    </mat-card-content>
</mat-card>
<br>
<mat-card>
    <mat-card-content>
        <div *ngIf="currentLanguage == 'de'">
            Falls Sie nur einen ersten Eindruck wünschen, empfehle ich Ihnen die <a routerLink="/profile">gekürzte und
                öffentliche
                Fassung</a> meines Entwicklerprofils.
        </div>
        <div *ngIf="currentLanguage == 'en'">
            If you just need a first impression, I'd like to recommend the <a routerLink="/profile">shortened and
                public version</a> of my developer profile to you.
        </div>
    </mat-card-content>
</mat-card>
<br>
<mat-card>
    <mat-card-content>
        <div *ngIf="currentLanguage == 'de'">
            Sollten Sie ein anderes Anliegen oder Interesse an meinem <a [routerLink]="['/contact/form']"
                [queryParams]="{filter: 'profile'}">ausführlichen Profil</a> haben, nutzen Sie bitte das
            Formular.
        </div>
        <div *ngIf="currentLanguage == 'en'">
            In case you have another request or interest in my <a [routerLink]="['/contact/form']"
                [queryParams]="{filter: 'profile'}">complete profile</a>, please use the contact form.
        </div>
        <br>
        <button mat-stroked-button color="primary" routerLink="/contact/form">
            {{ '__de__=Kontaktformular__en__=Contact form' | translate }}
        </button>
    </mat-card-content>
</mat-card>
<br>
<mat-card>
    <mat-card-content>
        <app-contactinfo [hideAddress]=" true" [hideUrl]="true"></app-contactinfo>
    </mat-card-content>
</mat-card>