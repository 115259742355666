import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SessionService } from '../../_services/session.service'

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})

export class SettingsComponent implements OnInit, OnDestroy {

    targetLanguage: string = '__de__=English__en__=Deutsch';
    targetTheme: string;
    themeIcon: string;
    subs: Subscription[] = [];

    constructor(private sessionService: SessionService) { }

    ngOnInit(): void {
        const themeDarkLabel: string = '__de__=Dunkel__en__=Dark';
        const themeLightLabel: string = '__de__=Hell__en__=Light';
        this.themeIcon = this.sessionService.getSession().theme == 'dark' ?
            'light_mode' : 'dark_mode';
        this.targetTheme = this.sessionService.getSession().theme == 'dark' ?
            themeLightLabel : themeDarkLabel;
        this.subs.push(this.sessionService.currentThemeChange.subscribe(value => {
            this.themeIcon = value == 'dark' ?
                'light_mode' : 'dark_mode';
            this.targetTheme = value == 'dark' ?
                themeLightLabel : themeDarkLabel;
        }));
    }

    ngOnDestroy(): void {
        this.subs.forEach(sub => sub.unsubscribe());
    }

    toggleLanguage($event: any) {
        if (this.sessionService.getSession().language == 'en') {
            this.sessionService.setLanguage('de');
        } else {
            this.sessionService.setLanguage('en');
        }
    }

    toggleTheme($event: any) {
        if (this.sessionService.getSession().theme == 'dark') {
            this.sessionService.setTheme('light');
        } else {
            this.sessionService.setTheme('dark');
        }
    }

}