import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Subscription } from 'rxjs';
import { SessionService } from '../_services/session.service';

@Pipe({
    name: 'translate',
    pure: false
})

export class TranslatePipe implements PipeTransform, OnDestroy {

    currentLanguage: string;
    subs: Subscription[] = [];

    constructor(public sessionService: SessionService) {
        this.currentLanguage = this.sessionService.getSession().language;
        this.subs.push(this.sessionService.currentLanguageChange.subscribe(value => {
            this.currentLanguage = value;
        }));
    }

    ngOnDestroy(): void {
        this.subs.forEach(sub => sub.unsubscribe());
    }

    transform(multiLanguageContent: string): string {
        let result: string;
        if (multiLanguageContent != null && multiLanguageContent.length > 0) {
            const pattern = new RegExp('(__[a-z]{2}__=[^__$]+)', 'g');
            const translations = multiLanguageContent.split(pattern).filter(value => value.length > 0);
            translations.forEach(phrase => {
                const replacePattern = new RegExp('__', 'g');
                const translation = phrase.replace(replacePattern, '').split('=');
                if (translation.length == 2 && translation[0] == this.currentLanguage) {
                    result = translation[1];
                    return;
                }
            });
        }
        return result;
    }
}