import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { BaseComponent } from '../_components/base.component'
import { SessionService } from '../_services/session.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseComponent implements OnInit, OnDestroy {

  subs: Subscription[] = [];

  step: number = 0;

  setStep(next: number) {
    if (next > 0) {
      this.router.navigate([''], { queryParams: { step: next } });
    } else {
      this.router.navigate(['']);
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this.subs.push(this.route.queryParamMap.subscribe((paramsMap: any) => {
      var queryStep = Number(paramsMap.params.step);
      if (queryStep > 0) {
        this.step = queryStep;
      } else {
        this.step = 0;
      }
    }));
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.subs.forEach(sub => sub.unsubscribe());
  }

}
