<div *ngIf="currentLanguage == 'de'">
    <p>
        Diese Website erhebt keinerlei personenbezogene Daten, auch nicht via Tracking, Cookies oder Logfiles.
        Im Falle einer Nutzung des Sprachbuttons oder bei Anpassung des Farbschemas (z.B. von hell auf dunkel), wird die
        gewählte Einstellung in der lokalen Browserdatenbank gespeichert.
    </p>
    <p>
        Sollten Sie dazu Fragen oder Einwände haben, wenden Sie sich gerne direkt an mich:
    </p>
    <p *ngIf="false">
        Sollten Sie das Kontaktformular nutzen, werden die von Ihnen eingegebenen Daten direkt an mich übermittelt und
        ausschließlich im Rahmen Ihrer aktiven Anfrage verarbeitet. Es findet zu keiner Zeit eine Weitergabe an Dritte
        statt. Allerdings können auch kriminelle Handlungen wie unerlaubte Serverzugriffe nicht vollständig
        ausgeschlossen
        werden. Daher übernehme ich keine Haftung, wenn Ihre Daten im Zuge einer Straftat abgefangen und zweckentfremdet
        werden.
        <br><br>
        Möchten Sie Ihre Anfrage widerrufen oder haben sonstige Fragen zum Datenschutz, wenden Sie sich bitte direkt an
        mich:
    </p>
</div>
<div *ngIf="currentLanguage == 'en'">
    <p>
        This website does not gather any personal information, not by tracking, cookies or logfiles. In case you use the
        language button or you change the color scheme (e.g. from bright to dark), the selected value
        will be stored in the local browser database.
    </p>
    <p>
        If there is another question or concern about privacy, please do not hesitate to contact me:
    </p>
    <p *ngIf="false">
        If you use the contact form, the data you have entered will be sent directly to me. Your
        information will be processed in order to answer your request. At no time your data will be passed on to third
        parties.
        However, criminal acts such as unauthorized server access, cannot be ruled out completely. Therefore I do not
        accept
        any liability if your data will get intercepted and misused in course of a criminal act.
        <br><br>
        If you like to revoke your request or there is another question about privacy, please do not hesitate to contact
        me:
    </p>
</div>
<app-contactinfo>
</app-contactinfo>