<div class="flexContainer">
    <div class="flexRow">
        <div class="flexCol">
            {{
                {
                    'Medium': 'C# / .NET',
                    'Large': 'C# / .NET',
                    'XLarge': 'C# / .NET',
                    'default': 'C#'
                } | shrink
            }}                        
        </div>
        <div class="flexCol fitContentSize">
            <app-stars [stars]="5" [count]="5"></app-stars>
        </div>
    </div>
    <div class="flexRow">
        <div class="flexCol">
            {{
                {
                    'Medium': '__de__=Fullstack mit Angular__en__=Fullstack with Angular',
                    'Large': '__de__=Fullstack mit Angular__en__=Fullstack with Angular',
                    'XLarge': '__de__=Fullstack mit Angular__en__=Fullstack with Angular',
                    'default': '__de__=Angular__en__=Angular'
                } | shrink | translate
            }}
        </div>
        <div class="flexCol fitContentSize">
            <app-stars [stars]="4" [count]="5"></app-stars>
        </div>
    </div>
    <div class="flexRow">
        <div class="flexCol">
            {{
                {
                    'Medium': '__de__=MobileApps mit Xamarin__en__=MobileApps with Xamarin',
                    'Large': '__de__=MobileApps mit Xamarin__en__=MobileApps with Xamarin',
                    'XLarge': '__de__=MobileApps mit Xamarin__en__=MobileApps with Xamarin',
                    'default': '__de__=Xamarin__en__=Xamarin'
                } | shrink | translate
            }}

        </div>
        <div class="flexCol fitContentSize">
            <app-stars [stars]="4" [count]="5"></app-stars>
        </div>
    </div>
    <div class="flexRow">
        <div class="flexCol">
            {{
                {
                    'Medium': 'Objective-C',
                    'Large': 'Objective-C',
                    'XLarge': 'Objective-C',
                    'default': 'ObjC'
                } | shrink
            }}            
        </div>
        <div class="flexCol fitContentSize">
            <app-stars [stars]="4" [count]="5"></app-stars>
        </div>
    </div>
        <div class="flexRow">
        <div class="flexCol" style="text-align: center;">
            <a routerLink="profile">
            {{
                '__de__=Mehr erfahren__en__=More' | translate
            }}
            </a>     
        </div>
    </div>

</div>