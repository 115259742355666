import { stringify } from "@angular/compiler/src/util";
import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { BaseComponent } from "../base.component";

@Component({
    selector: 'app-contactinfo',
    templateUrl: './contactinfo.component.html',
    styleUrls: ['./contactinfo.component.scss']
})

export class ContactInfoComponent extends BaseComponent implements AfterViewInit {

    @Input()
    hideAddress: boolean = false;

    @Input()
    hideUrl: boolean = false;

    @Input()
    showTaxId: boolean = false

    @ViewChild('email') email: ElementRef;

    ngAfterViewInit() {
        this.encode();
    }

    encode() {
        const address = 'mailt' + 'o:m' + 'e@' + 'sas' + 'chapau' + 'lu' + 's.de';
        let asciiValue: Array<number> = [];
        for (let i = 0; i < address.length; i++) {
            asciiValue.push(address.charCodeAt(i) + 3);
        }
        this.email.nativeElement.title = asciiValue.join('#');
    }

    decode() {
        const titleElements: Array<number> = this.email.nativeElement.title.split('#');
        let mailto: string = '';
        titleElements.forEach(element => {
            mailto += String.fromCharCode((element - 3))
        })
        return mailto;
    }

    onClick() {
        const mailto = this.decode();
        window.open(mailto);
    }

    show() {
        const mail = this.decode().substr(7);
        this.email.nativeElement.innerHTML = mail;
    }


}