<h1 mat-dialog-title>{{ '__de__=Fortfahren ?__en__=Resume ?' | translate }}</h1>
<div mat-dialog-content *ngIf="currentLanguage == 'de'">
    Der Inhalt ist auf einer anderen Website.
    <br>
    Möchten Sie dem Link folgen ?
</div>
<div mat-dialog-content *ngIf="currentLanguage == 'en'">
    The content ist on another website.
    <br>
    Do you want to follow the link ?
</div>
<div mat-dialog-actions>
    <button mat-button (click)="leaveSite()" mat-dialog-close>{{ '__de__=Ja__en__=Yes' | translate }}</button>
    <button mat-button mat-dialog-close>{{ '__de__=Nein__en__=No' | translate }}</button>
</div>