import { Component, Input, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '../_components/base.component'
import { SessionService } from '../_services/session.service';
@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent extends BaseComponent {
  @Input()
  mode: string = '';


  constructor(public sessionService: SessionService,
    public router: Router,
    public route: ActivatedRoute) {
    super(sessionService, router, route);

    if (this.router.getCurrentNavigation().extras.state?.mode != null) {
      this.mode = this.router.getCurrentNavigation().extras.state?.mode;
    }

  }

}
