import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ISession, Session } from '../_models/session';

@Injectable({ providedIn: 'root' })

export class SessionService {

    private readonly SESSION_NAME: string = 'session-spaulus-de';

    currentLanguageChange: Subject<string> = new Subject<string>();
    currentThemeChange: Subject<string> = new Subject<string>();

    constructor() {

    }


    saveSession(session: ISession) {
        if (localStorage.getItem(this.SESSION_NAME) != null) localStorage.removeItem(this.SESSION_NAME);
        localStorage.setItem(this.SESSION_NAME, JSON.stringify(session));
    }

    getSession(): ISession {
        var sessionContent = localStorage.getItem(this.SESSION_NAME);
        if (sessionContent != null && sessionContent.length > 0) {
            let session: ISession = <ISession>JSON.parse(sessionContent);
            if (session != null) {
                return session;
            }
        }
        let session: ISession = new Session();
        session.language = 'de';
        session.theme = 'light';
        this.saveSession(session);
        return session;
    }

    setLanguage(language: string) {
        if (language != 'de' && language != 'en') return;
        let session: ISession = this.getSession();
        session.language = language;
        this.currentLanguageChange.next(session.language);
        this.saveSession(session);
    }

    setTheme(theme: string) {
        if (theme != 'light' && theme != 'dark') return;
        let session: ISession = this.getSession();
        session.theme = theme;
        this.currentThemeChange.next(session.theme);
        this.saveSession(session);
    }

}