import { HttpClient } from '@angular/common/http';
import { Component, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '../_components/base.component'
import { SessionService } from '../_services/session.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent extends BaseComponent {
  numberOfPage: Number = 16;
}