<mat-card>
    <mat-card-title>
        {{ '__de__=Impressum__en__=Disclaimer' | translate }}
    </mat-card-title>
    <mat-card-content>
        <br>
        <div *ngIf="currentLanguage == 'de'">
            Für die Inhalte dieser Website bin ich verantwortlich. Bitte beachten Sie jedoch, dass
            ich keine Haftung für den Inhalt von Websites weiterführender Links übernehmen kann, da ich auf diese
            Websites keinen Einfluss habe.
        </div>
        <div *ngIf="currentLanguage == 'en'">
            I am responsible for the content of this website. However, please notice that I cannot be liable for linked
            third party websites and their content, because I have no influence on them.
        </div>
    </mat-card-content>
</mat-card>
<br>
<mat-card>
    <mat-card-content>
        <app-contactinfo [showTaxId]="false">
        </app-contactinfo>
    </mat-card-content>
</mat-card>