import { Component } from "@angular/core";
import { BaseComponent } from "../base.component";

@Component({
    selector: 'app-availability',
    templateUrl: './availability.component.html',
    styleUrls: ['./availability.component.scss']
})

export class AvailabilityComponent extends BaseComponent {

}


