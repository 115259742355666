import { Component, ElementRef, Inject, Input, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { Languagemenu, Menupoint } from '../../_models/index';
import { SessionService } from '../../_services/session.service'
import { MatDialog } from '@angular/material/dialog';
import { isPlatformBrowser } from '@angular/common';
import { Router, NavigationEnd } from "@angular/router";
import { LeaveSiteDialogComponent } from '../content/leavesitecontent/leavesitedialog.component';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-mainmenu',
    templateUrl: './mainmenu.component.html',
    styleUrls: ['./mainmenu.component.scss']
})


export class MainmenuComponent implements OnInit, OnDestroy {

    @Input()
    mode: string = 'horizontal';

    public menuPoints: Array<Menupoint>;
    subs: Subscription[] = [];

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private sessionService: SessionService,
        private leaveSiteDialog: MatDialog,
        private router: Router) {
        this.menuPoints = this.languageMenu.filter(m => m.language == sessionService.getSession().language)[0].menupoints;
        this.subs.push(this.sessionService.currentLanguageChange.subscribe(value => {
            this.menuPoints = this.languageMenu.filter(m => m.language == value)[0].menupoints;
        }));
    }

    ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.subs.push(this.router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    window.scrollTo(0, 0);
                }
            }));
        }
    }

    ngOnDestroy(): void {
        this.subs.forEach(sub => sub.unsubscribe());
    }

    public languageMenu: Array<Languagemenu> = [
        {
            language: 'de', menupoints: [
                { icon: 'description', route: '', text: 'Software Architekt & IT-Berater' },
                /*{ icon: 'architecture', route: 'approach', text: 'Mein Ansatz' },*/
                { icon: 'mail', route: 'contact', text: 'Kontakt' },
                /*{ icon: 'code', action: 'followExternalLink', text: 'Open Source', data: 'https://github.com/ckteebe' },*/
                { icon: 'shield', route: 'privacy', text: 'Datenschutz' },
                { icon: 'info', route: 'disclaimer', text: 'Impressum' },
            ]
        },
        {
            language: 'en', menupoints: [
                { icon: 'description', route: '', text: 'Software Architect & IT Consultant' },
                /*{ icon: 'architecture', route: 'approach', text: 'My Approach' },*/
                { icon: 'mail', route: 'contact', text: 'Contact' },
                /*{ icon: 'code', action: 'followExternalLink', text: 'Open Source', data: 'https://github.com/ckteebe' },*/
                { icon: 'shield', route: 'privacy', text: 'Privacy' },
                { icon: 'info', route: 'disclaimer', text: 'Disclaimer' },
            ]
        }
    ]


    onClick(menupoint: Menupoint) {
        if (menupoint.action != null) {
            if (menupoint.data) {
                this[menupoint.action](menupoint.data);
            } else {
                this[menupoint.action]();
            }
        }
    }



    followExternalLink(link: string) {
        this.leaveSiteDialog.open(LeaveSiteDialogComponent, { data: link });
    }

}