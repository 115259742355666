<div *ngIf="mode == ''">
    <div *ngIf="currentLanguage == 'de'">
        Es ist ein unerwarteter Fehler aufgetreten ! Bitte entschuldigen Sie die Un­an­nehm­lich­keiten.
    </div>
    <div *ngIf="currentLanguage == 'en'">
        There has been an unexpected error ! Please accept my apologies for the inconveniences.
    </div>
</div>

<div *ngIf="mode == 'contactSendFailed'">
    <div *ngIf="currentLanguage == 'de'">
        Leider konnte Ihre Anfrage nicht versendet werden. Bitte probieren Sie es erneut !
    </div>
    <div *ngIf="currentLanguage == 'en'">
        Unfortunately, your request could not be sent. Please try it again !
    </div>
</div>