import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { SessionService } from "src/app/_services/session.service";
import { BaseComponent } from "../../base.component";


@Component({
    selector: 'app-leavesite-dialog',
    templateUrl: './leavesitedialog.component.html'
})

export class LeaveSiteDialogComponent extends BaseComponent {

    link: string;

    constructor(public sessionService: SessionService,
        public router: Router,
        public route: ActivatedRoute,
        @Inject(MAT_DIALOG_DATA) private data: string) {
        super(sessionService, router, route);
        this.link = data;
    }

    leaveSite() {
        window.open(this.link, '_blank');
    }

}