import { Component } from '@angular/core';
import { BaseComponent } from '../_components/base.component'

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss']
})
export class DisclaimerComponent extends BaseComponent {
}

