import { Component } from "@angular/core";
import { BaseComponent } from '../../base.component'

@Component({
    selector: 'app-privacy-dialog',
    templateUrl: './privacydialog.component.html'
})

export class PrivacyDialogComponent extends BaseComponent {

}