<div class="menuContainer">
    <button mat-icon-button [matMenuTriggerFor]="settingsMenu">
        <mat-icon [ngStyle]="{'color':'white'}">language</mat-icon>
    </button>
    <mat-menu #settingsMenu="matMenu">
        <button mat-menu-item (click)="toggleLanguage($event)">
            <mat-icon>language</mat-icon>
            <span>{{ targetLanguage | translate }}</span>
        </button>
        <button mat-menu-item (click)="toggleTheme($event)">
            <mat-icon>{{ themeIcon }}</mat-icon>
            <span>{{ targetTheme | translate }}</span>
        </button>
    </mat-menu>
</div>